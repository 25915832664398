import React from 'react';
import Slider from "react-slick";

const Testimonial = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        className: "testimonial-active",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section class="testimonial padding-bottom-120 padding-top-110">
                <div class="container">
                    <div class="testi-shapes">
                        <span class="ts-1"><img src="/website/images/img/31.png" alt="" /></span>
                        <span class="ts-2"><img src="/website/images/img/32.png" alt="" /></span>
                        <span class="ts-3 item-animateTwo"><img src="/website/images/shapes/7.png" alt="" /></span>
                        <span class="ts-4"><img src="/website/images/shapes/26.png" alt="" /></span>
                    </div>
                    <div class="common-title-area text-center padding-bottom-50 wow fadeInUp">
                        <h3>testimonial</h3>
                        <h2>client <span>feedback</span></h2>
                    </div>
                    <Slider {...settings}>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-1.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Christ Deo</h6>
                                    <p>CEO A4Tech Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-2.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Lipayka Maya</h6>
                                    <p>CEO SoftTechit Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-1.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Christ Deo</h6>
                                    <p>CEO A4Tech Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-2.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Lipayka Maya</h6>
                                    <p>CEO SoftTechit Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-1.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Christ Deo</h6>
                                    <p>CEO A4Tech Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/testimonial/testi-2.png" alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Lipayka Maya</h6>
                                    <p>CEO SoftTechit Ltd.</p>
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Food Khan is a gret Restaurant from the University of Texas at Austin has been
                                researching
                                the benefits of frequent testing and the feedback
                                leads to. He explains that in the history of the study.</p>
                        </div>
                    </Slider>

                </div>
            </section>
        </>
    );
}

export default Testimonial;
