
// config
let production = 'No';//YES ==live NO ==localhost
export let SAAS_APPLICATION = 'NO'; //YES|NO  dont change it for live and server

// default config
export const saas_key = '$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu';
// export const saas_apiUrl = window.location.protocol + "//" +  window.location.hostname;          //for live
 export const saas_apiUrl ='https://demo.sitefido.com';                                             // for local

export let BASE_URL = '';
export let saas_apiParams = '';
export let saas_form_data = null;

if (production === "YES") 
{
  //for production
  BASE_URL = 
    window.location.protocol +
    "//" +
    window.location.hostname +
    `${window.location.port !== ""
      ? `:${window.location.port}`
      : `${!window.location.href.includes(".test") ? "/public" : ""}`
    }`;

  //saas config
  saas_apiParams = `saas_key=${saas_key}&domain=${BASE_URL}`;
  saas_form_data = {
    'saas_key': saas_key,
    'domain': BASE_URL
  };
}
else 
{
  // for localhost development
  BASE_URL =  
  "https://demo.sitefido.com/public";
    // window.location.protocol +
    // "//" +
    // window.location.hostname +    
    // "localhost:8000"+
    // "/api";

  // saas config
  saas_apiParams = `saas_key=${saas_key}&domain=khayer.automatefood.com`;
  saas_form_data = {
    'saas_key': saas_key,
    // 'domain': 'prince.automatefood.com'
    'domain': 'khayer.automatefood.com'
  };

}