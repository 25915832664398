import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// settings context
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import Templatesidebar from "./Templatesidebar";


const Hero = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);

    // state starts here
    const [heroContent, setHeroContent] = useState({
        heroTitle: getSystemSettings(templateOneSettings, "heroTitle"),
        heroTagTitle: getSystemSettings(templateOneSettings, "heroTagTitle"),
        heroBadgeTitle: getSystemSettings(templateOneSettings, "heroBadgeTitle"),
        heroNumber: getSystemSettings(templateOneSettings, "heroNumber"),
    });
    const [picture, setPicture] = useState([]);

    // onchange function
    const handleOnChange = (e) => {
        e.persist();
        setHeroContent({
            ...heroContent,
            [e.target.name]: e.target.value
        })
    }

    // use effect
    useEffect(() => {
        setHeroContent({
            heroTitle: getSystemSettings(templateOneSettings, "heroTitle"),
            heroTagTitle: getSystemSettings(templateOneSettings, "heroTagTitle"),
            heroBadgeTitle: getSystemSettings(templateOneSettings, "heroBadgeTitle"),
            heroNumber: getSystemSettings(templateOneSettings, "heroNumber"),
        })
    }, []);

    // handle image
    const handleImage = (e) => {
        setPicture({ image: e.target.files[0] });
    };

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("heroImage", picture.image);
        formData.append("heroTitle", heroContent.heroTitle);
        formData.append("heroTagTitle", heroContent.heroTagTitle);
        formData.append("heroBadgeTitle", heroContent.heroBadgeTitle);
        formData.append("heroNumber", heroContent.heroNumber);

        const url = BASE_URL + "/settings/store-hero-section";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}`,'Access-Control-Allow-Origin': '*' },
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            }
        });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Hero"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <Templatesidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">

                                        <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Hero title<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            type="text"
                                                            onChange={handleOnChange} className="form-control" name="heroTitle" placeholder="ENJOY OUR DELICIOUS FOOD" required="" defaultValue={heroContent.heroTitle} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Tag title<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="text" onChange={handleOnChange} className="form-control" name="heroTagTitle" placeholder="Get  your food" required="" defaultValue={heroContent.heroTagTitle} />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Badge title<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="text" onChange={handleOnChange} className="form-control" name="heroBadgeTitle" placeholder="order now" required="" defaultValue={heroContent.heroBadgeTitle} />
                                                    </div>
                                                </div>
                                                <div classNamde="form-group mt-3">
                                                    <div className="mb-2">
                                                        <label for="name" className="control-label">Delivery Number<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input type="number" onChange={handleOnChange} className="form-control" name="heroNumber" placeholder="123-59794069" required="" defaultValue={heroContent.heroNumber} />
                                                    </div>
                                                </div>

                                                <div className="form-group mt-4">
                                                    <div className="mb-2">
                                                        <label for="image" className="control-label">Image<span className="text-danger">*</span> <small className="text-secondary">(300 x 300 Preferable)</small></label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            name="image"
                                                            type="file"
                                                            className="form-control"
                                                            onChange={handleImage}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group mt-5 pb-2">
                                                    <div className="col-lg-12">
                                                        <button className="btn btn-primary px-5" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Hero;
