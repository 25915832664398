import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//pages & includes
import Templatesidebar from "./Templatesidebar";

const Gallery = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //states starts here
    const [picture, setPicture] = useState([]);
    const [getGalleryItem, setgetGalleryItem] = useState([]);
    const [loading, setLoading] = useState(true);

    // handle image
    const handleImage = (e) => {
        setPicture({ image: e.target.files[0] });
    };

    // useeffect
    useEffect(() => {
        const url = BASE_URL + "/settings/get-gallery-item";
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}`,'Access-Control-Allow-Origin': '*' },
        }).then((res) => {
            if (res.status == 200) {
                setgetGalleryItem(res.data.gallery);
            }
            setLoading(false);
        });

    }, [getGalleryItem]);

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("image", picture.image);
        const url = BASE_URL + "/settings/store-gallery-section";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` ,'Access-Control-Allow-Origin': '*'},
        }).then((res) => {
            if (res.data.status == 200) {

                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            } else if (res.data.status == 422) {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        });

    };

    // handleDelete
    const handleDelete = (e, name) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting";
        // send res
        const url = BASE_URL + `/settings/delete-gallery-item/${name}`;
        axios.delete(url, {
            headers: { Authorization: `Bearer ${getCookie()}` ,'Access-Control-Allow-Origin': '*'},
        }).then((res) => {
            if (res.data.status == 200) {
                toast.dismiss();
                toast.success(`${_t(t("deleted successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                thisClicked.innerText = "Delete";
            }
            else {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        }
        )
    }

    return (
        <>
            <Helmet>
                <title>{_t(t("Template"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="imgId" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handlesubmit} className="row card p-2 mx-3 mb-5 sm-text">
                                <div className="form-group mt-4">
                                    <div className="mb-2">
                                        <label for="image" className="control-label">Image<span className="text-danger">*</span> <small className="text-secondary">(255 x 280 Preferable)</small></label>
                                    </div>
                                    <div className="mb-2">
                                        <input
                                            required
                                            name="image"
                                            type="file"
                                            className="form-control"
                                            onChange={handleImage}
                                        />
                                    </div>
                                    <div className="form-group mt-5 pb-2">
                                        <div className="col-lg-12">
                                            <button className="btn btn-primary px-5" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <Templatesidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9  t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">

                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">
                                        <div className="col-lg-12 d-flex justify-content-end">
                                            <button type="button" className="btn btn-primary text-capitalize" data-toggle="modal" data-target="#imgId">
                                                add new
                                            </button>
                                        </div>
                                        {loading ? tableLoading() : (
                                            <>
                                                <div className="col-lg-12 d-flex justify-content-center">
                                                    <div className="t-pl-15 t-pr-15 w-100">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered table-hover min-table-height mt-4">
                                                                <thead className="align-middle">
                                                                    <tr>
                                                                        <th scope="col" className="sm-text text-capitalize align-middle text-center border-1 border">S/L</th>
                                                                        <th scope="col" className="sm-text text-capitalize align-middle text-center border-1 border">Image</th>
                                                                        <th scope="col" className="sm-text text-capitalize align-middle text-center border-1 border ">delete</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="align-middle">
                                                                    {getGalleryItem.map((item, index) => {
                                                                        return <>
                                                                            <tr key={index} className="align-middle">
                                                                                <th scope="row" className="xsm-text text-capitalize align-middle text-center">
                                                                                    {index + 1}
                                                                                </th>
                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    <img className="customWidth-100" src={`${BASE_URL}/uploads/${item.name}`} alt="" />
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center ">
                                                                                    <button onClick={(e) => handleDelete(e, item.name)} className="btn btn-danger text-capitalize">
                                                                                        delete
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Gallery;
