import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import Templatesidebar from "./Templatesidebar";

// templarte img
import img1 from './img/template1.png';
import img2 from './img/template2.png';

const Alltemplate = () => {
    const { t } = useTranslation();
    const history = useHistory();
    // getting context value
    const { loading, } = useContext(SettingsContext);
    // state starts here
    const [templateIndex, setTemplateIndex] = useState(2);

    // onchange function
    const handleOnChange = (e) => {
        setTemplateIndex(e);
    };

    // getTemplate value
    const getActivatedTemplate = (e) => {
        const url = BASE_URL + "/settings/get-template-value";
        return axios
            .get(url)
            .then((res) => {
                setTemplateIndex(res.data);
            });
    }
    // useEffect
    useEffect(() => {
        getActivatedTemplate();
    }, []);

    // handle submit
    const handlesubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("value", templateIndex);
        const url = BASE_URL + "/settings/set-template-value";
        axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` ,'Access-Control-Allow-Origin': '*'},
        }).then((res) => {
            if (res.data.status == 200) {
                toast.success(`${_t(t("updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                // re-render all component
                window.location.reload();

            } else {
                toast.error(`${_t(t("Try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });

            }
        });
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Template"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <Templatesidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
                            <div className="t-bg-white">
                                <div
                                    className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50 pl-3"
                                    data-simplebar
                                >
                                    <div className="t-pl-15 t-pr-15">
                                        <form onSubmit={handlesubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                </div>
                                                <div className=" col-md-6 col-lg-4 mb-4">
                                                    <label className="template-show-card card shadow">
                                                        <input
                                                            type="radio"
                                                            name="templateOne"
                                                            checked={templateIndex == 1 ? 'checked' : ''}
                                                            className="templateradio"
                                                            onChange={() => handleOnChange(1)}
                                                        />
                                                        <img
                                                            className="preview-img"
                                                            src={img1}
                                                            alt=""
                                                        />
                                                    </label>
                                                    <h5 className="template-title text-center">Template One</h5>
                                                </div>
                                                <div className=" col-md-6 col-lg-4 mb-4">
                                                    <label className="template-show-card card shadow">
                                                        <input
                                                            type="radio"
                                                            name="templateOne"
                                                            checked={templateIndex == 2 ? 'checked' : ''}
                                                            className="templateradio"
                                                            onChange={() => handleOnChange(2)}
                                                        />
                                                        <img
                                                            className="preview-img"
                                                            src={img2}
                                                            alt=""
                                                        />
                                                    </label>
                                                    <h5 className="template-title text-center">Template Two</h5>
                                                </div>
                                                <div className=" col-md-6 col-lg-4 mb-4">
                                                    <label className="template-show-card card shadow d-flex justify-content-center align-items-center px-5 text-center">
                                                        <h4>More are cooming soon</h4>
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="btn btn-danger text-capitalize template-active-btn">activate now</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Alltemplate;
