import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../BaseUrl';
import Slider from "react-slick";
import axios from 'axios';
// import '../../../node_modules/react-s'
//functions
import {
    _t,
    getSystemSettings,
    pageLoading,
    tableLoading
} from "../../../src/functions/Functions";
const Gallery = () => {

    const [getGalleryItem, setgetGalleryItem] = useState([]);
    const [loading, setLoading] = useState(true);
    // useEffect
    useEffect(() => {
        const url = BASE_URL + "/settings/get-templateone-gallery-item";

        axios.get(url).then((res) => {
            if (res.status == 200) {
                setgetGalleryItem(res.data.gallery);
            }
            setLoading(false);
        });

    }, []);


    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        centerMode: true,
        variableWidth: true,
        className: "slider-gallery-active",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                }
            }

        ]
    };

    const slider = () => {
        return getGalleryItem.map(function (slide, index) {
            return (
                <img key={index} src={`${BASE_URL}/uploads/${slide.name}`} alt="" />
            );
        })
    }

    return (

        <>
            {loading ? tableLoading() : (
            <div className="slider-gallery-img template-gallery padding-top-120 padding-bottom-120">
                <div className="container-fluid">
                    <Slider {...settings}>
                        {slider()}
                    </Slider>
                </div>
            </div>
             )} 

        </>
    );
}

export default Gallery;
