import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
    _t,
    getCookie,
    pageLoading
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Twiliosetup = () => {

    const { t } = useTranslation();
    const history = useHistory();

    // hooks
    const [twilioDetails, settwilioDetails] = useState({
        TWILIO_SID: null,
        TWILIO_TOKEN: null,
        TWILIO_FROM: null
    });

    // checkbox state
    const [isChecked, setIsChecked] = useState(false);
    // loading
    const [loading, setloading] = useState(true);

    // onchange
    const handleOnchange = (e) => {
        settwilioDetails({ ...twilioDetails, [e.target.name]: e.target.value });
    }
    // handle checkbox
    const handleCheckbox = (e) => {
        setIsChecked(e.target.checked);
    }

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        const url = BASE_URL + `/settings/setup-twilio`;
        let formData = new FormData();
        formData.append("TWILIO_SID", twilioDetails.TWILIO_SID);
        formData.append("TWILIO_TOKEN", twilioDetails.TWILIO_TOKEN);
        formData.append("TWILIO_FROM", twilioDetails.TWILIO_FROM);
        formData.append("show", isChecked);
        // formData.append("show", isChecked);
        return axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` ,'Access-Control-Allow-Origin': '*' },
            })
            .then((res) => {
                toast.success(`${_t(t("Twilio setup successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            })
    }
    // get twilio info
    const getTwilioInfo = (e) => {
        setloading(true);
        const url = BASE_URL + `/settings/get-twilio-info`;
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}`  ,'Access-Control-Allow-Origin': '*'},
            })
            .then((res) => {

                settwilioDetails({
                    ...twilioDetails,
                    TWILIO_SID: res.data.TWILIO_SID,
                    TWILIO_TOKEN: res.data.TWILIO_TOKEN,
                    TWILIO_FROM: res.data.TWILIO_FROM,
                });

                if (res.data.TWILIO_SHOW == "YES") {
                    setIsChecked(true);
                } else {
                    setIsChecked(false);
                }
                setloading(false);
            })
        // alert('hello');
    }


    //useEffect == componentDidMount
    useEffect(() => {
        getTwilioInfo();
    }, []);
    return (
        <>
            <Helmet>
                <title>{_t(t("Twilio"))}</title>
            </Helmet>

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white p-3 p-md-5">
                                <div>
                                    <h4 className="text-secodary my-4">Setup Twilio</h4>
                                </div>
                                <hr />
                                {loading == true ? pageLoading() :

                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="smsname" className="form-label">Sms Name</label>
                                            <input disabled type="text" className="form-control" id="smsname" value="Twilio" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tokensecret" className="form-label">Token/secrect</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="TWILIO_SID"
                                                required
                                                onChange={handleOnchange}
                                                defaultValue={twilioDetails.TWILIO_SID}
                                                id="tokensecret"
                                                placeholder="token/secret" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tokenkey" className="form-label">Id/Key</label>
                                            <input type="text"
                                                name="TWILIO_TOKEN"
                                                required
                                                onChange={handleOnchange}
                                                defaultValue={twilioDetails.TWILIO_TOKEN}
                                                className="form-control"
                                                id="tokenkey" placeholder="id/key" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="smsform" className="form-label">Sms From</label>
                                            <input type="text"
                                                name="TWILIO_FROM"
                                                required
                                                onChange={handleOnchange}
                                                defaultValue={twilioDetails.TWILIO_FROM}
                                                className="form-control" id="smsform" placeholder="+8801234567" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="checkbox"
                                                id="twiliocheckbox"
                                                defaultValue={isChecked}
                                                onChange={handleCheckbox}
                                                checked={isChecked}
                                            />
                                            <label htmlFor="twiliocheckbox" className="form-label ml-4">hide/show</label>
                                        </div>
                                        <div className="mt-3">
                                            <button type="submit" className="btn btn-primary text-capitalize">save</button>
                                        </div>
                                    </form>
                                }

                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default Twiliosetup;
